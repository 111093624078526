:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
  background-color: initial;
}

#root {
  height: 100%;
}

html {
  height: 100%;
  width: 100%;
}

body {
  overflow: auto;
  height: 100%;
  position: relative;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  html,
  body {
    height: initial;
    overflow: initial;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
